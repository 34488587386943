import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Image from "gatsby-image"
import SEO from "../components/seo"

const HomePage = ({ data }) => (
  <Layout>
    <SEO title="Home" />
    <Image fluid={data.illustration.childImageSharp.fluid} />
    <div>
      <p>Welcome to Feuery.net, a portal via short stories to the fantasy world of Th’eia.</p>
      <p>The material on this site is purely for your enjoyment, and hopefully you will find it so. If you do, please like us, and pass word on to others of like mind.</p>
      <p>More stories, field guides and other source material will be added over time.</p>
    </div>
    <div classname="content-blabla-text">
      <p>This site provides free access to short stories of Th’eia.They may be copied for personal non - commercial, non - derivative use.</p>
      <p>Stories and site content other than images are &copy; Allan Williams 2014.</p>
      <p>Images of miniatures and backgrounds remain the copyright of the original copyright holders.</p>
      <p><a rel="license" href="http://creativecommons.org/licenses/by-nc-nd/3.0/deed.en_US"><img alt="Creative Commons License" style={{ borderWidth: 0 }} src="http://i.creativecommons.org/l/by-nc-nd/3.0/88x31.png" /></a></p>
      <p>This work is licensed under a <a rel="license" href="http://creativecommons.org/licenses/by-nc-nd/3.0/deed.en_US">Creative Commons Attribution - NonCommercial - NoDerivs 3.0 Unported License.</a></p>
    </div>
  </Layout>
)

export default HomePage

export const query = graphql`
  query {
    illustration: file(relativePath: { eq: "page_home.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
